import { Cell, DataTable, Grid, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import { isProcedimentoSisab } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { LoadingIndicator } from 'components/loading'
import {
  useHistoricoAtendimentoIndividualDwQuery,
  useHistoricoConcatenadaProcedimentoQuery,
  useHistoricoConcatenadaZikaQuery,
} from 'graphql/hooks.generated'
import { DimensaoProcedimento, FatoAtendimentoIndividual } from 'graphql/types.generated'
import { partition } from 'lodash'
import React, { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import {
  FichasConcatenadasHistorico,
  HistoricoPanelProps,
  historicoSubsectionStyles,
} from 'view/atendimentos/detail/historico/model/model-historico'
import {
  convertFatoMedicoesToHistoricoMedicaoModel,
  convertFatoToReceitaMedicamento,
  hasAllAttrsNull,
  hasMedicoes,
  mergeResultadosExamesDw,
  mountInfosAdministrativasAtendimentoCompartilhado,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoDetailTable from '../../../../table/HistoricoDetailTable'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoMedicoesTableBox from '../../HistoricoMedicoesTableBox'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import HistoricoResultadoExamesTable from '../../HistoricoResultadoExamesTable'
import { ResultadosDeExamesZikaContent } from '../../zika/ResultadosDeExamesZikaContent'
import HistoricoPlanoPecPrescricaoPanel from '../consulta/soap/plano/HistoricoPlanoPecPrescricaoPanel'
import GravidezAvaliacaoSection from './GravidezAvaliacaoSection'

const renderExameSolicitado = (item: DimensaoProcedimento) => (
  <Text>
    {item.descricaoProcedimento} - {item.codigoProcedimento}
  </Text>
)

interface HistoricoAtendimentoIndividualDwPanelProps extends HistoricoPanelProps {
  fichasConcatenadas: FichasConcatenadasHistorico
}

function HistoricoAtendimentoIndividualDwPanel(props: HistoricoAtendimentoIndividualDwPanelProps) {
  const { uuidRegistro, idadeOrigemAtendimento, cpfOuCnsCidadao, fichasConcatenadas } = props

  const {
    data: { historicoAtendimentoIndividualDw: historico },
    loading,
  } = useHistoricoAtendimentoIndividualDwQuery({
    variables: { input: { isOrigemPec: false, uuidRegistro, cpfOuCnsCidadao } },
  })

  const {
    data: { historicoZikaDetail: zikaConcatenada },
    loading: loadingZikaConcatenada,
  } = useHistoricoConcatenadaZikaQuery({
    variables: { input: { isOrigemPec: false, uuidRegistro: fichasConcatenadas?.uuidZika, cpfOuCnsCidadao } },
    skip: !fichasConcatenadas?.uuidZika,
  })

  const {
    data: { historicoProcedimentoDw: procedimentoConcatenada },
    loading: loadingProcedimentoConcatenada,
  } = useHistoricoConcatenadaProcedimentoQuery({
    variables: { input: { isOrigemPec: false, uuidRegistro: fichasConcatenadas?.uuidProcedimento, cpfOuCnsCidadao } },
    skip: !fichasConcatenadas?.uuidProcedimento,
  })

  if (loading || loadingZikaConcatenada || loadingProcedimentoConcatenada) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const [procedimentosSisab, procedimentosSigtap] = partition(procedimentoConcatenada?.procedimentos, (procedimento) =>
    isProcedimentoSisab(procedimento.codigoProcedimento)
  )

  const medicoes = convertFatoMedicoesToHistoricoMedicaoModel(historico as FatoAtendimentoIndividual)

  const hasAnyMedicao = hasMedicoes(medicoes)
  const hasMedicoesPreNatal = historico.stGravidezPlanejada || historico.nuIdadeGestacional
  const hasExamesAvaliados = !isEmpty(historico.examesAvaliados) || !isEmpty(historico.resultadosExamesEspecificos)
  const hasMedicamentos = !isEmpty(historico.medicamentos)
  const hasDadosPlano = !isEmpty(historico.examesSolicitados) || fichasConcatenadas?.uuidProcedimento || hasMedicamentos
  const hasCiapCidAvaliados = !isEmpty(historico.cidsCiapsAvaliados)
  const hasNasfPolo = !isEmpty(historico.nasfPolo)
  const hasProcedimentoSigtap = !isEmpty(procedimentosSigtap)
  const hasCondutas = !isEmpty(historico.condutaDesfecho)
  const hasEncaminhamentos = !isEmpty(historico.encaminhamentos)

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentoCompartilhado(historico)}
      isAtendObservacao={historico.stFicouEmObservacao}
      tipoParticipacaoCidadao={historico.dimTipoParticipacaoCidadao?.descricao}
    >
      <HistoricoSection section='S'>
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      </HistoricoSection>
      <HistoricoSection section='O'>
        <VFlow>
          {!hasExamesAvaliados && !hasAnyMedicao && !historico.dimensaoAleitamento && (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
          {hasAnyMedicao && <HistoricoMedicoesTableBox sectionStyle='O' medicoes={medicoes} />}
          {hasMedicoesPreNatal && (
            <HistoricoDetailTable title='Medições pré-natal' sectionStyle='O'>
              <VFlow>
                <Grid>
                  {!isEmpty(historico.stGravidezPlanejada) && (
                    <Cell size={2}>
                      <InfoLabel title='Gravidez planejada'>{historico.stGravidezPlanejada ? 'Sim' : 'Não'}</InfoLabel>
                    </Cell>
                  )}
                  {historico.nuIdadeGestacional && (
                    <Cell size={2}>
                      <InfoLabel title='Idade gestacional'>
                        {'semana'.pluralizeAndConcatValue(historico.nuIdadeGestacional)}
                      </InfoLabel>
                    </Cell>
                  )}
                </Grid>
              </VFlow>
            </HistoricoDetailTable>
          )}
          {hasExamesAvaliados && (
            <HistoricoResultadoExamesTable
              examesResultados={mergeResultadosExamesDw(historico as FatoAtendimentoIndividual)}
            />
          )}
          {historico.dimensaoAleitamento && !hasAllAttrsNull(historico.dimensaoAleitamento) && (
            <VFlow vSpacing={0.5}>
              <Heading level={5} style={historicoSubsectionStyles.objetivo}>
                Puericultura
              </Heading>
              <InfoLabel title='Tipo de aleitamento'>{historico.dimensaoAleitamento.descricao}</InfoLabel>
            </VFlow>
          )}
        </VFlow>
        {fichasConcatenadas?.uuidZika && (
          <VFlow vSpacing={0.5}>
            <Heading level={3} style={historicoSubsectionStyles.objetivo}>
              Síndrome por Zika/Microcefalia
            </Heading>
            <ResultadosDeExamesZikaContent detalhamento={zikaConcatenada} />
          </VFlow>
        )}
      </HistoricoSection>
      <HistoricoSection section='A'>
        <VFlow vSpacing={0.5}>
          {hasCiapCidAvaliados && (
            <DataTable
              rows={historico.cidsCiapsAvaliados.sort((a, b) => a.localeCompare(b))}
              columns={[
                {
                  name: 'nome',
                  header: (
                    <Text style={historicoSubsectionStyles.avaliacao}>
                      Problemas e/ou condições avaliados neste atendimento
                    </Text>
                  ),
                  render: (ciapCid: string) => <Text>{ciapCid}</Text>,
                },
              ]}
            />
          )}
          <GravidezAvaliacaoSection nuGestasPrevias={historico.nuGestasPrevias} nuPartos={historico.nuPartos} />
        </VFlow>
      </HistoricoSection>
      <HistoricoSection section='P'>
        {!hasDadosPlano ? (
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        ) : (
          <VFlow>
            {!isEmpty(historico.examesSolicitados) && (
              <VFlow vSpacing={0.5}>
                <Heading level={5} style={historicoSubsectionStyles.plano}>
                  Exames solicitados
                </Heading>
                <DataTable<DimensaoProcedimento>
                  rows={historico.examesSolicitados.sort((a, b) =>
                    a.descricaoProcedimento.localeCompare(b.descricaoProcedimento)
                  )}
                  columns={[
                    {
                      name: 'nome',
                      header: 'Nome',
                      render: renderExameSolicitado,
                    },
                  ]}
                />
              </VFlow>
            )}
            {!isEmpty(procedimentosSisab) && (
              <VFlow vSpacing={0.5}>
                <Heading level={5} style={historicoSubsectionStyles.plano}>
                  Intervenções e/ou procedimentos clínicos
                </Heading>
                <HistoricoDetailTable hasLines>
                  <VFlow>
                    {procedimentosSisab
                      ?.sort((a, b) => a.descricaoProcedimento.localeCompare(b.descricaoProcedimento))
                      .map((procedimento) => (
                        <Text key={procedimento.id}>
                          {procedimento.descricaoProcedimento} - {procedimento.codigoProcedimento}
                        </Text>
                      ))}
                  </VFlow>
                </HistoricoDetailTable>
              </VFlow>
            )}
            {hasMedicamentos && (
              <VFlow vSpacing={0.5}>
                <Heading style={historicoSubsectionStyles.plano} level={5}>
                  Medicamentos prescritos
                </Heading>
                <HistoricoPlanoPecPrescricaoPanel
                  prescricaoMedicamentos={historico.medicamentos.map((medicamento) =>
                    convertFatoToReceitaMedicamento(medicamento)
                  )}
                />
              </VFlow>
            )}
          </VFlow>
        )}
      </HistoricoSection>
      <HistoricoSection section='D'>
        <VFlow>
          {hasProcedimentoSigtap && (
            <VFlow>
              <Heading level={5}>Procedimentos Administrativos (SIGTAP)</Heading>
              <HistoricoDetailTable hasLines>
                <VFlow>
                  {procedimentosSigtap
                    ?.sort((a, b) => a.descricaoProcedimento.localeCompare(b.descricaoProcedimento))
                    .map((procedimento) => (
                      <Text key={procedimento.id}>
                        {procedimento.descricaoProcedimento} - {procedimento.codigoProcedimento}
                      </Text>
                    ))}
                </VFlow>
              </HistoricoDetailTable>
            </VFlow>
          )}
          {historico.dimensaoRacionalidadeSaude && !hasAllAttrsNull(historico.dimensaoRacionalidadeSaude) && (
            <InfoLabel title='Racionalidade em saúde'>{historico.dimensaoRacionalidadeSaude?.descricao}</InfoLabel>
          )}
          {hasCondutas && <InfoLabel title='Conduta'>{stringGrouper(...historico.condutaDesfecho)}</InfoLabel>}
          {hasEncaminhamentos && (
            <InfoLabel title='Encaminhamento'>{stringGrouper(...historico.encaminhamentos)}</InfoLabel>
          )}
        </VFlow>
      </HistoricoSection>
      {(hasNasfPolo || historico.dimensaoModalidadeAd) && (
        <HistoricoSection section='I'>
          <VFlow vSpacing={0.5}>
            {historico.dimensaoModalidadeAd && (
              <InfoLabel title='Atenção domiciliar'>{historico.dimensaoModalidadeAd.descricao}</InfoLabel>
            )}
            {hasNasfPolo && (
              <InfoLabel title={(historico.stNasf ? 'NASF' : 'eMulti') + ' / Polo'}>
                {stringGrouper(...historico.nasfPolo)}
              </InfoLabel>
            )}
          </VFlow>
        </HistoricoSection>
      )}
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAtendimentoIndividualDwPanel)
