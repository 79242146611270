import { Alert, Cell, FormControl, Grid, Heading, HFlow, VFlow } from 'bold-ui'
import { isEstagio } from 'components/auth/useSessionUtils'
import { CheckboxField, DateField, Form, FormRenderProps, NumberField, RichTextField } from 'components/form'
import { LotacaoEditableTable } from 'components/form/editable-table/LotacaoEditableTable'
import { ProcedimentoSelectField } from 'components/form/field/select/ProcedimentoSelectField'
import { RecordStringSelectField } from 'components/form/final-form/RecordStringSelectField/RecordStringSelectField'
import { LotacaoResponsavelSection } from 'components/lotacao-responsavel/LotacaoResponsavelSection'
import { subMonths } from 'date-fns'
import { css } from 'emotion'
import createDecorator from 'final-form-calculate'
import { TemaSaudeEnum, TipoAtividadeEnum, TipoProcedimentoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { turnoRecord } from 'types/enums'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'

import { LocalAtividadeField } from '../componentes/LocalAtividadeField'
import { ParticipanteEditableTable } from '../componentes/ParticipantesEditableTable/ParticipanteEditableTable'
import {
  AtividadeColetivaFormModel,
  LOTACAO_ESTAGIO_SEM_PERMISSAO,
  PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO,
  PRATICAS_SAUDE_EDUCACAO_PSE,
  praticaSaudeRecord,
  publicoAlvoRecord,
  temaSaudeRecord,
  tipoAtividadeRecord,
  TIPOS_ATIVIDADE_EM_SAUDE,
  TIPOS_ATIVIDADE_EM_SAUDE_EDUCACAO_PSE,
} from '../model-atividadeColetiva'
import { createAtividadeColetivaSaudeCalculations } from './calculator-atividadeColetivaSaude'
import {
  isCidadaosParticipantesRequired,
  isOnlyEducacaoPseSelected,
  isPraticaSaudeDisabled,
  isPraticaSaudePnct,
  isProcedimentoDisabled,
} from './utils-atividadeColetivaSaude'

const path = metaPath<AtividadeColetivaFormModel>()

const decorator = createDecorator(...createAtividadeColetivaSaudeCalculations(path))

interface AtividadeColetivaSaudeFormProps {
  initialValues?: AtividadeColetivaFormModel
}

export default function AtividadeColetivaSaudeForm(props: AtividadeColetivaSaudeFormProps) {
  const { initialValues } = props
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const renderForm = (formProps: FormRenderProps<AtividadeColetivaFormModel>) => {
    const { values } = formProps
    const actionRespAtividadeColetiva = values.lotacaoResponsavel.lotacao.cbo.actions.atividadeColetiva

    return (
      <Grid gap={2}>
        <Cell size={12}>
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Responsabilidade de registro</Heading>
            <LotacaoResponsavelSection name={path.lotacaoResponsavel} />
            {!actionRespAtividadeColetiva.enabled && (
              <Alert type='danger' inline>
                {actionRespAtividadeColetiva.hint}
              </Alert>
            )}
            {isEstagio(values.lotacaoResponsavel.lotacao) && (
              <Alert type='danger' inline>
                {LOTACAO_ESTAGIO_SEM_PERMISSAO}
              </Alert>
            )}
          </VFlow>
        </Cell>

        <Cell xs={3} lg={2} style={styles.section}>
          <DateField
            name={path.dataAtividade}
            label='Data da atividade'
            title='Data da atividade'
            minDate={subMonths(serverTime, 12)}
            maxDate={serverTime}
            required
          />
        </Cell>

        <Cell size={2} style={styles.section}>
          <RecordStringSelectField name={path.turno} record={turnoRecord} label='Turno' title='Turno' required />
        </Cell>

        <Cell size={2} style={styles.section}>
          <NumberField
            name={path.numeroParticipantes}
            label='Nº de participantes'
            title='Nº de participantes'
            placeholder='Informe o número'
            min={1}
            max={999}
            maxLength={3}
            required
          />
        </Cell>

        <Cell size={3} style={styles.section}>
          <FormControl
            label='Programa saúde na escola'
            required={values.temasSaude?.includes(TemaSaudeEnum.SEMANA_SAUDE_NA_ESCOLA)}
          >
            <HFlow>
              <CheckboxField name={path.atividadeEducacaoPse} label='Educação' style={styles.checkbox} />
              <CheckboxField name={path.atividadeSaudePse} label='Saúde' style={styles.checkbox} />
            </HFlow>
          </FormControl>
        </Cell>

        <Cell size={12}>
          <LocalAtividadeField
            name={path.localAtividade}
            onlyInepEnabled={values.atividadeEducacaoPse || values.atividadeSaudePse}
          />
        </Cell>

        <Cell size={12} style={styles.section}>
          <Heading level={2} style={styles.title}>
            Profissionais envolvidos
          </Heading>
        </Cell>

        <Cell size={12}>
          {isOnlyEducacaoPseSelected(values) ? (
            'Não é possível cadastrar profissionais para atividades de educação do Programa Saúde na Escola.'
          ) : (
            <LotacaoEditableTable name={path.lotacoesEnvolvidas} />
          )}
        </Cell>

        <Cell size={12} style={styles.section}>
          <Heading level={2} style={styles.title}>
            Dados da atividade
          </Heading>
        </Cell>

        <Cell size={4}>
          <RecordStringSelectField
            name={path.tipoAtividade}
            record={tipoAtividadeRecord}
            subset={
              isOnlyEducacaoPseSelected(values) ? TIPOS_ATIVIDADE_EM_SAUDE_EDUCACAO_PSE : TIPOS_ATIVIDADE_EM_SAUDE
            }
            label='Tipo da atividade'
            title='Tipo da atividade'
            required
          />
        </Cell>

        <Cell size={8} />

        <Cell size={4}>
          <RecordStringSelectField
            name={path.publicoAlvo}
            record={publicoAlvoRecord}
            label='Público alvo'
            title='Público alvo'
            required
            multiple
          />
        </Cell>

        <Cell size={4}>
          <RecordStringSelectField
            name={path.temasSaude}
            record={temaSaudeRecord}
            label='Temas para a saúde'
            title='Temas para a saúde'
            required={
              values.tipoAtividade && values.tipoAtividade !== TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO
            }
            multiple
          />
        </Cell>

        <Cell size={4} />

        <Cell size={4}>
          <RecordStringSelectField
            name={path.praticasSaude}
            record={praticaSaudeRecord}
            subset={
              (isOnlyEducacaoPseSelected(values) && PRATICAS_SAUDE_EDUCACAO_PSE) ||
              (values.tipoAtividade === TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO && PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO)
            }
            label='Praticas em saúde'
            title='Praticas em saúde'
            required={values.tipoAtividade === TipoAtividadeEnum.AVALIACAO_PROCEDIMENTO_COLETIVO}
            disabled={isPraticaSaudeDisabled(values)}
            multiple
          />
        </Cell>

        <Cell size={4}>
          <ProcedimentoSelectField
            name={path.procedimento}
            label='Código do SIGTAP'
            tipoProcedimento={TipoProcedimentoEnum.GRUPO}
            dataAtendimento={dateAsYyyyMmDd(serverTime)}
            disabled={isProcedimentoDisabled(values)}
          />
        </Cell>

        <Cell size={12}>
          <RichTextField
            name={path.anotacoes}
            label='Anotações da atividade'
            placeholder='Insira as anotações da atividade'
            maxLength={4000}
            height={6}
          />
        </Cell>

        <Cell size={12} style={styles.section}>
          <Heading level={2} style={styles.title}>
            Participantes
          </Heading>
        </Cell>

        <Cell size={12}>
          <ParticipanteEditableTable
            name={path.participantes}
            now={serverTime}
            profissionalResponsavel={values.lotacaoResponsavel?.lotacao?.profissional}
            profissionaisEnvolvidos={values.lotacoesEnvolvidas?.map((item) => item.lotacao?.profissional)}
            isPraticaSaudePnct={isPraticaSaudePnct(values?.praticasSaude)}
            required={isCidadaosParticipantesRequired(values?.tipoAtividade)}
          />
        </Cell>
      </Grid>
    )
  }

  // TODO Foundation #21956 - Implementar o onSubmit
  return (
    <Form<AtividadeColetivaFormModel>
      initialValues={initialValues}
      render={renderForm}
      decorators={[decorator]}
      onSubmit={undefined}
    />
  )
}

const styles = {
  section: css`
    margin-top: 1.5rem;
  `,
  title: css`
    margin-bottom: 0.5rem;
  `,
  checkbox: css`
    margin-top: 0.25rem;
  `,
}
