import { AtendimentoIndividualRetificacaoViewQuery } from 'graphql/types.generated'
import {
  convertFichasNotificacaoCasoSuspeitoQueryRetificacaoToForm,
  convertProcedimentosAdmistrativosQueryRetificacaoToForm,
} from 'view/atendimentos/detail/soap/finalizacao/converter-finalizacao'
import { convertSubjetivoQueryRetificacaoToForm } from 'view/atendimentos/detail/soap/subjetivo/converter'

import { SoapState } from '../atendimentos/atendimento-individual/model'

type AtendimentoIndividualRetificacaoModel = AtendimentoIndividualRetificacaoViewQuery['atendimento']['atendimentoProfissional']

export const initialValueRetificacao = (
  atendimentoIndividualRetificacao: AtendimentoIndividualRetificacaoModel
): SoapState => {
  const {
    evolucaoSubjetivo,
    procedimentosAtendimento,
    notificacoesCasoSuspeito,
    racionalidadeSaude,
  } = atendimentoIndividualRetificacao
  return {
    openedAccordions: ['S', 'O', 'P', 'D'],
    //TODO (RNG): Implementar conversores do model para o form do SoapState (#22001)
    subjetivo: convertSubjetivoQueryRetificacaoToForm(evolucaoSubjetivo),
    medicoesAnteriores: [],
    finalizacao: {
      procedimentosAdministrativos: convertProcedimentosAdmistrativosQueryRetificacaoToForm(procedimentosAtendimento),
      fichasNotificacaoCasoSuspeito: convertFichasNotificacaoCasoSuspeitoQueryRetificacaoToForm(
        notificacoesCasoSuspeito
      ),
      racionalidadeEmSaude: racionalidadeSaude,
    },
    plano: {},
    objetivo: {},
  }
}
